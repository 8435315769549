/* https://css-tricks.com/snippets/sass/strip-unit-function/
* Removes the unit of a length
* @param {Number} $number - Number to remove unit from
* @return {Number} - Unitless number
*/
/* ---------- Global ---------- */
.ie9 #logo {
  padding-top: 6px; }

.ie9 #footer-logo {
  padding-top: 9px; }

p a[href^="http"]:not([href*="causingeffect."]):after,
span a[href^="http"]:not([href*="causingeffect."]):after,
h2 a[href^="http"]:not([href*="causingeffect."]):after,
h3 a[href^="http"]:not([href*="causingeffect."]):after,
h4 a[href^="http"]:not([href*="causingeffect."]):after {
  top: -.5em; }

@media only screen and (min-width: 1280px) {
  .ie8 #menu li {
    margin: 0 25px 0 0; } }

@media only screen and (min-width: 960px) {
  .ie8 #menu li {
    margin: 0 14px 0 0; } }

@media only screen and (min-width: 960px) {
  .ie9 #menu li a {
    padding: 73px 9px 22px 9px; }
  .ie8 #menu li a {
    padding: 73px 8px 22px 8px; }
  .ie7 #menu li a {
    padding: 73px 8px 22px 8px; } }

img {
  width: auto;
  height: auto;
  max-width: 100%; }

.ie7 #menu li {
  display: inline; }

.ie7 .ce_tweet_holder.home_tweets {
  clear: both;
  width: 100%;
  margin: 7px 0 8px 0;
  display: inline;
  width: 95%;
  float: left;
  clear: both; }

.ie7 .ce_tweet {
  min-height: 60px;
  display: inline-block;
  width: auto;
  padding: 12px; }

.ie7 .ce_tweet_right {
  width: auto;
  display: inline-block;
  float: none;
  padding-right: 12px; }

.ie7 .ce_tweet_left {
  float: left;
  display: inline-block;
  position: static; }

.ie7 .ce_tweet_actions span.icon-twitter {
  margin-right: 5px;
  top: 2px; }

.ie7 #featured-software .home-slide,
.ie7 #featured-web .home-slide {
  display: none; }

.ie7 #featured-software .home-slide:first-child,
.ie7 #featured-web .home-slide:first-child {
  display: block; }

.ie7 #featured-software-holder {
  width: 42%;
  float: left;
  padding: 0 0 0 15px; }

.ie7 #featured-web-holder {
  width: 53.5%;
  float: right;
  padding: 0 15px 0 0; }

.ie7 #footer-logo span {
  font-family: 'CausingEffect' !important;
  font-weight: normal; }

.ie7 .side-icon,
.ie7 #footer-logo,
.ie7 #about-logo {
  display: none; }

#browser-upgrade {
  margin: 0; }

.space {
  display: inline;
  width: auto; }

.lt-ie8 .space {
  width: .2543em;
  display: inline-block; }
